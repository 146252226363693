import React from 'react';
//import Layout from '../components/layout';
import Pageheader from '../Utils/pageheader';
import { MDBContainer } from 'mdbreact';
import Bot from '../components/Committes/bot';
import ExecutiveCommittee from '../components/Committes/ec';
import Founding from '../components/Committes/Founding';
import SEO from '../components/seo';

const Current = () => {
    return (
        <>
            <SEO title="Current Team" />
            <MDBContainer className="mt-4">
                <h3 className="title font-weight-bolder text-secondary">Board Of Trustees</h3>
                <Pageheader />
                <Bot />
                <h3 className="title font-weight-bolder text-secondary mt-5">Executive Committee</h3>
                <Pageheader />
                <ExecutiveCommittee />
                <h3 className="title font-weight-bolder text-secondary mt-5">Founding Committee</h3>
                <Pageheader />
                <Founding />
            </MDBContainer>
        </>
    );
};

export default Current;