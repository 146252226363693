import React from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBBadge } from 'mdbreact';
import { useStaticQuery, graphql } from 'gatsby';
const Founding = () => {
    const list = useStaticQuery(graphql`
    {
        allFoundingJson {
            edges {
              node {
                FundraisingCommittee {
                  name
                }
                ExecutiveCommittee {
                  name
                }
                BuildingCommittee {
                  name
                }
              }
            }
          }
    }`);;
    const nodeData = list.allFoundingJson.edges[0].node;
    const executiveDataItems = nodeData.ExecutiveCommittee.map((mem, index) => {
        return (<MDBBadge color="default" className="my-2 mx-2" style={{ fontSize: "large" }} key={index}>{mem.name}</MDBBadge>);
    });
    const fundDataItems = nodeData.FundraisingCommittee.map((mem, index) => {
        return (<MDBBadge color="primary" className="my-2 mx-2" style={{ fontSize: "large" }} key={index}>{mem.name}</MDBBadge>);
    });
    const buildDataItems = nodeData.BuildingCommittee.map((mem, index) => {
        return (<MDBBadge color="info" className="my-2 mx-2" style={{ fontSize: "large" }} key={index}>{mem.name}</MDBBadge>);
    });
    return (
        <MDBContainer className="mx-4 px-4 mt-4">
            <MDBRow >
                <MDBCol md="4">
                    <h4 className="title font-weight-bolder text-default">Executive Committee</h4>
                    {executiveDataItems}
                </MDBCol>
                <MDBCol md="4">
                    <h4 className="title font-weight-bolder text-primary">Fundraising Committee</h4>
                    {fundDataItems}
                </MDBCol>
                <MDBCol md="4">
                    <h4 className="title font-weight-bolder text-info">Building Committee</h4>
                    {buildDataItems}
                </MDBCol>
            </MDBRow>
        </MDBContainer>

    );
}
export default Founding;