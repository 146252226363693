import React from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBCard, MDBCardBody, MDBCardTitle, MDBCardText } from 'mdbreact';
import { useStaticQuery, graphql } from 'gatsby';
const ExecutiveCommittee = () => {
    const list = useStaticQuery(graphql`
    {
        allCurrentJson {
            edges {
              node {
                name
                title
                id
              }
            }
          }
    }`);;
    const nodeData = list.allCurrentJson.edges;
    const listItems = nodeData.map((edge, index) => {
        const nameSizeData = (edge.node.name.length > 0) ? '16px' : '';
        const titleSizeData = (edge.node.title.length > 1) ? '14px' : '';
        return (
            <MDBCard style={{ width: "20rem", float: "left" }} className="m-2">
                <MDBCardBody>
                    <MDBCardTitle style={{ fontSize: nameSizeData }} className="text-default font-weight-bolder">{edge.node.name}</MDBCardTitle>
                    <MDBCardText>
                        <span className="lead" style={{ fontSize: titleSizeData }}> {edge.node.title} </span><br />
                        {/* <span className="lead" style={{ fontSize: titleSizeData }}> {edge.node.tel} </span> */}
                    </MDBCardText>
                </MDBCardBody>
            </MDBCard>
        );
    });
    return (
        <MDBContainer className="mx-4 px-4">
            <MDBRow className="">



            </MDBRow>

            <MDBRow>

                <MDBCol>
                    {listItems}
                </MDBCol>
            </MDBRow>
        </MDBContainer>

    );
}
export default ExecutiveCommittee;