import React from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBBadge } from 'mdbreact';
import { useStaticQuery, graphql } from 'gatsby';
const Bot = () => {
    const list = useStaticQuery(graphql`
    {
        allBotJson {
            edges {
              node {
                chairman
                vicechairman
                members
              }
            }
          }
    }`);;
    const nodeData = list.allBotJson.edges[0].node;
    const listItems = list.allBotJson.edges[0].node.members.map((member, index) => {
        return (<MDBBadge color={(parseInt(index) % 2 === 0) ? "default" : "info"} className="my-2 mx-2" style={{ fontSize: "large" }}>{member}</MDBBadge>);
    });
    return (
        <MDBContainer className="mx-4 px-4">
            <MDBRow className="">
                <ul style={{ listStyle: "none" }}>
                    <li><h3 className="title"><strong className="font-weight-bold text-primary">Chairman: </strong><strong> {nodeData.chairman}</strong></h3></li>
                    <li><h3 className="title"><strong className="font-weight-bold text-primary">Vice Chairman: </strong><strong> {nodeData.vicechairman}</strong></h3></li>
                </ul>


            </MDBRow>
            <h4 className="font-weight-bold ">Trustees</h4>
            <MDBRow>

                <MDBCol>
                    {listItems}
                </MDBCol>
            </MDBRow>
        </MDBContainer>

    );
}
export default Bot;